import {
  ActionIcon,
  Divider,
  Group,
  Popover,
  Stack,
  Title,
  useMantineTheme,
  Text,
  Modal,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { CaretRight, X } from '@phosphor-icons/react';
import { PebbleButtonSet } from 'components/ui';
import classes from './TicketTypesCard.module.scss';
import { PebbleButtonsEnum } from 'enums';
import { formatPenceToPounds } from 'utils/formatPrice';

interface IPriceInfo {
  price?: number;
  prorataPrice?: number;
}

export const PriceInfo: React.FC<IPriceInfo> = ({ price, prorataPrice }) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const [subsPriceInfoOpen, { toggle }] = useDisclosure(false);

  const Info = (
    <>
      <Group justify="end">
        <ActionIcon
          onClick={toggle}
          className={classes.closeButton}
          variant={isMobile ? 'transparent' : 'filled'}
          aria-label="close-button"
        >
          <X size={20} color={isMobile ? theme.colors.gray[5] : 'white'} weight="bold" />
        </ActionIcon>
      </Group>
      <Stack className={classes.priceInfoTooltipContent}>
        <Title order={4} c={theme.colors.blue[8]}>
          Price Information
        </Title>
        <Divider color={theme.colors.gray[2]} />
        <Text>
          Subscriptions charge a pro-rated amount for the first paid month, based on the number of
          sessions left in that month.
        </Text>

        <Text>
          For the first paid month, each session costs{' '}
          <Text component="span" fw={700}>
            {formatPenceToPounds(prorataPrice || 0)}
          </Text>
          .
        </Text>

        <Text>
          After that, a fixed monthly subscription of{' '}
          <Text component="span" fw={700}>
            {formatPenceToPounds(price || 0)}
          </Text>{' '}
          will be charged on the 1st of each month.
        </Text>

        <Text>
          <Text component="span" fw={700}>
            NOTE:
          </Text>{' '}
          The pro-rated cost for the first month will be capped at the fixed monthly subscription
          cost.
        </Text>
      </Stack>
    </>
  );

  const InfoButton = (
    <PebbleButtonSet
      className={classes.priceInfoButton}
      btnVariant={PebbleButtonsEnum.TERTIARY}
      onClick={toggle}
    >
      View price information
      <CaretRight size={20} />
    </PebbleButtonSet>
  );

  if (isMobile) {
    return (
      <>
        {InfoButton}
        <Modal
          opened={subsPriceInfoOpen}
          onClose={toggle}
          withCloseButton={false}
          fullScreen
          zIndex={2000}
        >
          {Info}
        </Modal>
      </>
    );
  }
  return (
    <Popover
      position="left-end"
      withArrow
      arrowSize={24}
      arrowOffset={80}
      opened={subsPriceInfoOpen}
      onChange={toggle}
    >
      <Popover.Target>{InfoButton}</Popover.Target>
      <Popover.Dropdown className={classes.priceInfoTooltip}>{Info}</Popover.Dropdown>
    </Popover>
  );
};
