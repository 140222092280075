import { Flex, Spoiler, Stack, Text, useMantineTheme } from '@mantine/core';
import classNames from 'classnames';
import TrialsOnBlocksBadge from 'components/TrialsOnBlocksBadge/TrialsOnBlocksBadge';
import { useBasket } from 'context/BasketContext';
import dayjs from 'dayjs';
import { BlockTrialTypeEnum } from 'enums';
import { useMemo } from 'react';
import { Product } from 'types';
import { Actions, trackAction } from 'utils/amplitude';
import classes from './SelectedSessionsList.module.scss';

interface ISelectedSessionsListProps {
  blockTrialType: BlockTrialTypeEnum;
  blockTrialSessionCount: number;
  hideEndTimes?: boolean;
  fromBasket?: boolean;
  isSubscription: boolean;
  selectedSession: Product | null;
}

const SelectedSessionsList: React.FC<ISelectedSessionsListProps> = ({
  blockTrialType,
  blockTrialSessionCount,
  hideEndTimes = false,
  fromBasket = false,
  isSubscription,
  selectedSession,
}) => {
  const theme = useMantineTheme();
  const { trialsOnBlocksSelected, basketItems } = useBasket();

  const spoilerSplitDates = useMemo(() => {
    const cutOff = 3;
    return fromBasket && basketItems
      ? [basketItems.slice(0, cutOff), basketItems.slice(cutOff, basketItems.length)]
      : selectedSession?.allBlockDates
      ? [
          selectedSession?.allBlockDates.slice(0, cutOff),
          selectedSession?.allBlockDates.slice(cutOff, selectedSession?.allBlockDates.length),
        ]
      : [[]];
  }, [selectedSession?.allBlockDates, fromBasket, basketItems]);

  const postTrialSessions = selectedSession?.allBlockDates
    ?.filter((session) => !session.isInPast && session.spotsLeft !== 0)
    .slice(blockTrialSessionCount);

  const getShowMoreLabel = () => {
    if (trialsOnBlocksSelected && postTrialSessions && postTrialSessions.length > 3)
      return `+${postTrialSessions.length - 3} more`;

    if (
      !fromBasket &&
      !isSubscription &&
      selectedSession?.allBlockDates &&
      selectedSession.allBlockDates?.length > 3
    ) {
      const availableBlocks = selectedSession.allBlockDates.filter(
        (session) => !session.isInPast && session.spotsLeft > 0,
      );
      if (availableBlocks.length > 3) return `+${availableBlocks.length - 3} more`;
    }

    if (fromBasket && basketItems.length > 3) return `+${basketItems.length - 3} more`;

    return '';
  };

  const trialSessions = selectedSession?.allBlockDates
    ?.filter((session) => !session.isInPast && session.spotsLeft !== 0)
    .slice(0, blockTrialSessionCount);

  return (
    <>
      {trialsOnBlocksSelected ? (
        <>
          <Text size="xs" fw={600} c={theme.colors.gray[6]}>
            TRIAL SESSION{blockTrialSessionCount > 1 ? 'S' : ''}:
          </Text>
          <TrialsOnBlocksBadge
            blockTrialType={blockTrialType}
            blockTrialSessionCount={blockTrialSessionCount}
          />
          <Stack gap={0}>
            {trialSessions?.map((session, index) => {
              return (
                <Flex className={classes.selectedClassesTimes} key={`${session.date}-${index}`}>
                  <Text>{dayjs(session.date).format('DD MMM')}</Text>
                  {hideEndTimes ? (
                    <Text ml={8}>{session.startTime}</Text>
                  ) : (
                    <Text ml={8}>
                      {session.startTime} - {session.endTime}
                    </Text>
                  )}
                </Flex>
              );
            })}
          </Stack>
          {postTrialSessions && postTrialSessions.length > 0 ? (
            <>
              <Text c={theme.colors.blue[8]} size="xs" mt="md" mb="xs">
                If you choose to go ahead after the trial session, these are the dates you will
                purchase.
              </Text>
              <Flex direction={'column'}>
                <Spoiler
                  maxHeight={spoilerSplitDates[1]?.length > 0 ? 65 : 9999}
                  showLabel={getShowMoreLabel()}
                  hideLabel="See less"
                  classNames={{
                    control: classNames(classes.spoilerControl, {
                      [classes.postTrialSessions]: postTrialSessions,
                    }),
                  }}
                  onClick={() => {
                    trackAction(Actions.VIEW_DATES_DETAILS);
                  }}
                >
                  {postTrialSessions.map((session, index) => {
                    return (
                      <Flex
                        className={classes.selectedClassesTimes}
                        key={`${session.date}-${index}`}
                      >
                        <Text c={theme.colors.gray[5]}>{dayjs(session.date).format('DD MMM')}</Text>
                        {hideEndTimes ? (
                          <Text ml={8} c={theme.colors.gray[5]}>
                            {session.startTime}
                          </Text>
                        ) : (
                          <Text ml={8} c={theme.colors.gray[5]}>
                            {session.startTime} - {session.endTime}
                          </Text>
                        )}
                      </Flex>
                    );
                  })}
                </Spoiler>
              </Flex>
            </>
          ) : (
            <Text c={theme.colors.blue[8]} size="xs" mt="md">
              There are no sessions beyond the trial dates.
            </Text>
          )}
        </>
      ) : (
        <>
          <Text c={theme.colors.gray[6]} fw={600} size="xs">
            Selected session(s):
          </Text>
          <Flex direction={'column'} style={{ overflowY: 'auto' }} mb={0}>
            <Spoiler
              maxHeight={spoilerSplitDates[1]?.length > 0 ? 65 : 9999}
              showLabel={getShowMoreLabel()}
              hideLabel="See less"
              classNames={{
                control: classes.spoilerControl,
              }}
              onClick={() => {
                trackAction(Actions.VIEW_DATES_DETAILS);
              }}
            >
              {!fromBasket &&
                !isSubscription &&
                selectedSession?.allBlockDates?.map((session, index) => {
                  if (!session.isInPast && session.spotsLeft !== 0) {
                    return (
                      <Flex
                        className={classes.selectedClassesTimes}
                        key={`${session.date}-${index}`}
                      >
                        <Text>{dayjs(session.date).format('DD MMM')}</Text>
                        {hideEndTimes ? (
                          <Text ml={8}>{session.startTime}</Text>
                        ) : (
                          <Text ml={8}>
                            {session.startTime} - {session.endTime}
                          </Text>
                        )}
                      </Flex>
                    );
                  }
                })}

              {fromBasket &&
                basketItems.length > 0 &&
                basketItems.map((session) => (
                  <Flex className={classes.selectedClassesTimes} key={session.checkoutValue}>
                    <Text>{session.dateRange}</Text>
                    {hideEndTimes ? (
                      <Text ml={8}>{session.startTime}</Text>
                    ) : (
                      <Text ml={8}>
                        {session.startTime} - {session.endTime}
                      </Text>
                    )}
                  </Flex>
                ))}
            </Spoiler>

            {isSubscription && selectedSession?.weekdays && (
              <Text size="sm" fw={600}>
                Next: {dayjs(selectedSession.subscriptionNextSessionDate).format('Do MMMM YYYY')}
              </Text>
            )}
          </Flex>
        </>
      )}
    </>
  );
};

export default SelectedSessionsList;
