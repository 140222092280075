import { useEffect, useMemo, useState } from 'react';
import { Box, Text, Tabs, useMantineTheme, Badge, Group } from '@mantine/core';
import { ActivityDetails, Product } from 'types';
import { SubscriptionTrialTypeEnum, ActivityTypeEnum, DaysOfWeekEnum } from 'enums';
import { Subscriptions } from '@icons';
import classes from 'components/ActivityDetails/ActivityDetails.module.scss';
import { trackAction, Actions } from 'utils/amplitude';
import { getSpotsLeftText } from './utils';
import RadioTimePicker from './RadioTimePicker/RadioTimePicker';
import GetTicketsButton from 'components/GetTicketsButton/GetTicketsButton';
import dayjs from 'dayjs';
import { PebbleSelect } from 'components/ui';
import BookContainer from './BookContainer/BookContainer';
import classNames from 'classnames';
import queryString from 'query-string';
import { CaretDown, Clock, Coins, HandHeart, ReceiptX } from '@phosphor-icons/react';

interface ISubsTimesAndAvailabilityProps {
  activityDetails: ActivityDetails;
  handleGetTickets: (session: Product) => void;
  selectedSession: Product | null;
  setSelectedSession(val: Product | null): void;
  setUserSelectedRadio?(val: boolean): void;
}

const SubsTimesAndAvailability: React.FC<ISubsTimesAndAvailabilityProps> = ({
  activityDetails,
  handleGetTickets,
  selectedSession,
  setSelectedSession,
  setUserSelectedRadio,
}) => {
  const theme = useMantineTheme();
  const { activity, subscriptions } = activityDetails;
  const schedules = subscriptions.products;

  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState<DaysOfWeekEnum>(
    activityDetails.weekdays[0],
  );

  const filteredSchedule = schedules.filter((schedule) => {
    if (selectedDayOfWeek && schedule.weekdays)
      return schedule.weekdays.includes(selectedDayOfWeek);
  });

  const getSubsNextSessionDate = (date?: string) => {
    if (!date) {
      return '';
    }
    return dayjs(date).format('dddd, Do MMMM YYYY');
  };

  useEffect(() => {
    const getDefaultSession = (): Product | null => {
      if (selectedDayOfWeek) {
        const findSchedule = schedules.filter((x) => x.weekdays?.[0] === selectedDayOfWeek);
        return findSchedule[0];
      } else {
        return schedules[0];
      }
    };
    setSelectedSession(getDefaultSession());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDayOfWeek, schedules]);

  const params = {
    checkoutKey: selectedSession?.checkoutKey,
    checkoutValue: selectedSession?.checkoutValue,
  };
  const paramsString = queryString.stringify(params);

  const waitlistLink = `/waitlist/${activity.id}?${paramsString}`;

  const hasAvailableSubTickets = subscriptions.tickets.some((ticket) => ticket.isAvailable);

  const trialBadgeLabel = useMemo(() => {
    if (activity.subscriptionTrialType === SubscriptionTrialTypeEnum.FREE_TRIAL) {
      return 'Free trial available';
    }
    if (activity.subscriptionTrialType === SubscriptionTrialTypeEnum.PAID_TRIAL) {
      return 'Paid trial available';
    }
    return '';
  }, [activity.subscriptionTrialType]);

  const trialBadgeIcon = useMemo(() => {
    if (activity.subscriptionTrialType === SubscriptionTrialTypeEnum.FREE_TRIAL) {
      return <HandHeart size={16} />;
    }
    if (activity.subscriptionTrialType === SubscriptionTrialTypeEnum.PAID_TRIAL) {
      return <Coins size={16} />;
    }
    return null;
  }, [activity.subscriptionTrialType]);

  return (
    <>
      <Box className={classes.sectionText}>
        <Tabs
          classNames={{
            list: classes.bookingsTabsList,
            tab: classes.bookingsTabOneTabOnly,
            panel: classes.tabPanel,
          }}
          defaultValue={'subscription'}
          keepMounted={false}
        >
          <Tabs.List>
            <Tabs.Tab
              className={!activity.allowBlockBookings ? classes.disablePointer : ''}
              value="subscription"
              leftSection={<Subscriptions color={theme.colors.blue[8]} />}
            >
              Monthly subscription
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="subscription">
            {activity.activityType === ActivityTypeEnum.SUBSCRIPTION && (
              <>
                {/* {single day single time} */}
                {activityDetails.weekdays.length === 1 && schedules.length === 1 && (
                  <>
                    <Text>{`Next: ${getSubsNextSessionDate(
                      schedules[0].subscriptionNextSessionDate,
                    )}`}</Text>
                    <Box className={classes.detailsItem}>
                      <Clock size={22} />
                      <div className={classes.timeDetails}>
                        <Text fw="400" component="p">
                          {`${schedules[0].startTime} - ${schedules[0].endTime}`}
                        </Text>
                        <Text
                          fw="600"
                          className={classNames(classes.sessionSpots, {
                            [classes.soldOut]: schedules[0].spotsLeft === 0,
                          })}
                        >
                          {getSpotsLeftText(schedules[0].spotsLeft)}
                        </Text>
                      </div>
                    </Box>
                  </>
                )}

                {/* {single day multi times} */}
                {activityDetails.weekdays.length === 1 && schedules.length > 1 && (
                  <>
                    <RadioTimePicker
                      heading="Select a time:"
                      nextSessionSubheading={`Next: ${getSubsNextSessionDate(
                        schedules[0]?.subscriptionNextSessionDate,
                      )}`}
                      setUserSelectedRadio={setUserSelectedRadio}
                      products={schedules}
                      selectedSession={selectedSession}
                      onSelect={setSelectedSession}
                      hideEndTimes={activity.hideEndTimes}
                    />
                  </>
                )}

                {/* {multi days for select} */}
                {activityDetails.weekdays.length > 1 && (
                  <>
                    <PebbleSelect
                      value={selectedDayOfWeek}
                      radius="md"
                      required
                      placeholder="Pick a day"
                      data={activityDetails.weekdays}
                      classNames={{
                        root: classes.dropdownRoot,
                        dropdown: classes.dropdown,
                        input: classes.input,
                        section: classes.rightSection,
                      }}
                      rightSection={
                        <CaretDown size={12} weight="bold" fill={theme.colors.blue[8]} />
                      }
                      styles={{ section: { pointerEvents: 'none' } }}
                      onChange={(e) => {
                        setSelectedDayOfWeek?.(e as DaysOfWeekEnum);
                        trackAction(Actions.SELECTED_DAY);
                      }}
                    />
                    {selectedDayOfWeek && (
                      <RadioTimePicker
                        heading="Select a time:"
                        nextSessionSubheading={`Next: ${getSubsNextSessionDate(
                          filteredSchedule[0]?.subscriptionNextSessionDate,
                        )}`}
                        setUserSelectedRadio={setUserSelectedRadio}
                        products={filteredSchedule.length > 0 ? filteredSchedule : schedules}
                        selectedSession={selectedSession}
                        onSelect={setSelectedSession}
                        hideEndTimes={activity.hideEndTimes}
                      />
                    )}
                  </>
                )}
              </>
            )}

            {selectedSession && selectedSession?.spotsLeft > 0 && hasAvailableSubTickets && (
              <GetTicketsButton
                isSubscription
                mt="md"
                fullWidth
                radius="xl"
                size="lg"
                onClick={() => {
                  if (selectedSession) {
                    handleGetTickets(selectedSession);
                  }
                  trackAction(Actions.GET_TICKETS, {
                    activityLocation: activity.isOnline ? 'online' : 'in-person',
                    activityType: activity.activityType,
                  });
                }}
              >
                Book now
              </GetTicketsButton>
            )}

            <Group gap={0} wrap="nowrap" justify="space-between" my="md">
              {activity.subscriptionTrialType !== SubscriptionTrialTypeEnum.NO_TRIAL && (
                <Badge
                  title={trialBadgeLabel}
                  classNames={{
                    root: classNames(classes.rootBadge, {
                      [classes.trialBadge]:
                        activity.subscriptionTrialType === SubscriptionTrialTypeEnum.FREE_TRIAL,
                      [classes.paidTrialBadge]:
                        activity.subscriptionTrialType === SubscriptionTrialTypeEnum.PAID_TRIAL,
                    }),
                    label: classes.innerBadge,
                  }}
                  leftSection={trialBadgeIcon}
                >
                  {trialBadgeLabel}
                </Badge>
              )}
              <Badge
                title="Cancel anytime with provider"
                classNames={{
                  root: classNames(classes.rootBadge, {
                    [classes.trialBadge]:
                      activity.subscriptionTrialType === SubscriptionTrialTypeEnum.FREE_TRIAL,
                    [classes.paidTrialBadge]:
                      activity.subscriptionTrialType === SubscriptionTrialTypeEnum.PAID_TRIAL,
                  }),
                  label: classes.innerBadge,
                }}
                leftSection={<ReceiptX size={16} color={theme.white} weight="light" />}
              >
                Cancel anytime with provider
              </Badge>
            </Group>
            <Text className={classes.cancelCopy} ta="center">
              Subject to provider T&Cs
            </Text>

            {(selectedSession?.spotsLeft === 0 || !hasAvailableSubTickets) && (
              <BookContainer
                selectedSessionSpotsLeft={selectedSession?.spotsLeft}
                waitlistLink={waitlistLink}
                activityType={activity.activityType}
              />
            )}
          </Tabs.Panel>
        </Tabs>
      </Box>
    </>
  );
};

export default SubsTimesAndAvailability;
