import { Box, Spoiler, Stack, Text, useMantineTheme } from '@mantine/core';
import { DateAvailabilityType, Product } from 'types';
import { PreCheckoutBasketTicket, Schedule } from 'interfaces';
import { useMemo } from 'react';
import { trackAction, Actions } from 'utils/amplitude';
import classes from './SelectedSessionsList.module.scss';
import classNames from 'classnames';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useCheckoutDetailsContext } from 'context/CheckoutDetailsContext';
import { getBookedDatesList } from 'components/CheckoutDetails/CheckoutDetailsUtils';
import { BlockTrialTypeEnum } from 'enums';
import TrialsOnBlocksBadge from 'components/TrialsOnBlocksBadge/TrialsOnBlocksBadge';
dayjs.extend(advancedFormat);

interface ISelectedSessionsList {
  basketTickets: PreCheckoutBasketTicket[];
  product?: Product;
}

const SessionItem: React.FC<{
  session: DateAvailabilityType | Schedule;
  hideEndTimes: boolean;
  postTrialSessions?: boolean;
}> = ({ session, hideEndTimes, postTrialSessions = false }) => {
  const dateString = dayjs(session.date).format('DD MMM');
  const isSoldOut = session.spotsLeft === 0;
  const timeString = hideEndTimes ? session.startTime : `${session.startTime} - ${session.endTime}`;

  return (
    <div
      className={classNames(classes.sessionDateInfo, { [classes.soldOut]: isSoldOut })}
      key={`${session.date}`}
    >
      <Text className={classNames({ [classes.postTrialSessions]: postTrialSessions })}>
        {dateString}
        {' - '}
        {timeString}
      </Text>
    </div>
  );
};

const SpoilerContent: React.FC<{
  sessions: (DateAvailabilityType | Schedule)[];
  showMoreLabel: string;
  withPostTrialSessions?: boolean;
}> = ({ sessions, showMoreLabel, withPostTrialSessions = false }) => {
  return (
    <Box>
      <Spoiler
        maxHeight={sessions.length > 0 ? 55 : 9999}
        showLabel={showMoreLabel}
        hideLabel="See less"
        classNames={{
          control: classNames(classes.spoilerControl, {
            [classes.postTrialSessions]: withPostTrialSessions,
          }),
        }}
        onClick={() => trackAction(Actions.VIEW_DATES_DETAILS)}
      >
        {sessions.map((session, index) => (
          <SessionItem
            key={`${session.date}-${index}-session`}
            session={session}
            hideEndTimes={false}
            postTrialSessions={withPostTrialSessions}
          />
        ))}
      </Spoiler>
    </Box>
  );
};

const SelectedSessionsList: React.FC<ISelectedSessionsList> = ({ basketTickets, product }) => {
  const theme = useMantineTheme();
  const { isSubscription, basket, isBlockTrialCheckout } = useCheckoutDetailsContext();
  const { activity, tickets } = basket;

  const blockTrialType = tickets[0].blockTrialType || BlockTrialTypeEnum.NO_TRIAL;
  const blockTrialSessionCount = tickets[0].blockTrialSessionCount || 0;

  const dates = useMemo(
    () => getBookedDatesList(product, basketTickets) || [],
    [product, basketTickets],
  );

  const hideEndTimes = activity?.hideEndTimes;

  const trialSessions = useMemo(
    () =>
      product?.allBlockDates
        ?.filter((session) => !session.isInPast && session.spotsLeft !== 0)
        .slice(0, blockTrialSessionCount),
    [product?.allBlockDates, blockTrialSessionCount],
  );

  const postTrialSessions = useMemo(
    () =>
      product?.allBlockDates
        ?.filter((session) => !session.isInPast && session.spotsLeft !== 0)
        .slice(blockTrialSessionCount) || [],
    [product?.allBlockDates, blockTrialSessionCount],
  );

  const showMoreLabel = useMemo(() => {
    if (isBlockTrialCheckout && postTrialSessions && postTrialSessions.length > 3)
      return `+${postTrialSessions.length - 3} more`;

    if (!isSubscription && product?.allBlockDates && product?.allBlockDates.length > 3) {
      const availableBlocks = product?.allBlockDates.filter(
        (session) => !session.isInPast && session.spotsLeft > 0,
      );
      if (availableBlocks.length > 3) return `+${availableBlocks.length - 3} more`;
    }

    if (dates && dates.length > 3) return `+${dates.length - 3} more`;

    return '';
  }, [isBlockTrialCheckout, postTrialSessions, product?.allBlockDates, isSubscription, dates]);

  return (
    <>
      {isSubscription && product ? (
        <div className={classes.sessionDateInfo}>
          <Text>NEXT:</Text>
          <Text>
            {product.subscriptionNextSessionDate
              ? dayjs(product.subscriptionNextSessionDate).format('DD MMM')
              : ''}{' '}
            {`${product.startTime} - ${product.endTime}`}
          </Text>
        </div>
      ) : isBlockTrialCheckout && trialSessions ? (
        <>
          <Text size="xs" fw={600} c={theme.colors.gray[6]} mb="xs">
            TRIAL SESSION{blockTrialSessionCount > 1 ? 'S' : ''}:
          </Text>
          <TrialsOnBlocksBadge
            blockTrialType={blockTrialType}
            blockTrialSessionCount={blockTrialSessionCount}
          />
          <Stack gap={0} mt="xs">
            {trialSessions.map((session, index) => (
              <SessionItem
                session={session}
                hideEndTimes={hideEndTimes}
                key={`${session.date}-${index}-session`}
              />
            ))}
          </Stack>
          {postTrialSessions.length > 0 ? (
            <>
              <Text size="xs" mt="lg" mb="xs" fw={400} c={theme.colors.blue[8]}>
                If you choose to go ahead after the trial session, these are the dates you will
                purchase.
              </Text>
              <SpoilerContent
                sessions={postTrialSessions}
                withPostTrialSessions={true}
                showMoreLabel={showMoreLabel}
              />
            </>
          ) : (
            <Text size="xs" mt="lg" fw={400} c={theme.colors.blue[8]}>
              There are no sessions beyond the trial dates.
            </Text>
          )}
        </>
      ) : (
        <>
          <Text c={theme.colors.gray[6]} fw={600} size="xs" mb="xs">
            Selected sessions:
          </Text>
          <SpoilerContent showMoreLabel={showMoreLabel} sessions={dates} />
        </>
      )}
    </>
  );
};

export default SelectedSessionsList;
