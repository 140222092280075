import {
  AnonymisedBookingAddonsType,
  AnonymisedBookingTicketSessionsType,
  AnonymisedBookingTicketType,
  BookedClass,
} from 'types';

export const getUniqueSessions = (bookedSessions: AnonymisedBookingTicketSessionsType[]) => {
  if (!bookedSessions) {
    return [];
  }
  return bookedSessions.filter(
    (session, index, self) =>
      index ===
      self.findIndex(
        (s) => s.sessionStart === session.sessionStart && s.sessionEnd === session.sessionEnd,
      ),
  );
};

export const calculateTickets = (bookedTickets: AnonymisedBookingTicketType[]) => {
  if (bookedTickets.length <= 0) {
    return '';
  }

  const ticketTotals = bookedTickets.reduce((acc: Record<string, number>, item) => {
    const name = item?.ticket?.name || '';

    if (acc[name] === undefined) {
      // If no ticket name found add name with a quantity of 1
      acc[name] = 1;
    } else {
      // Update quantity of ticket name
      acc[name] += 1;
    }

    return acc;
  }, {});

  const ticketsString = Object.entries(ticketTotals)
    .map(([key, value]) => {
      return `${value} x ${key}`;
    })
    .join('\n');

  return ticketsString;
};

export const calculatePerSessionAddons = (perSessionAddons: AnonymisedBookingAddonsType[]) => {
  if (!perSessionAddons || perSessionAddons.length === 0) {
    return [];
  }

  const addonTotals = perSessionAddons.reduce(
    (acc: Record<string, { count: number; totalPrice: number }>, addon) => {
      const { name, price } = addon;

      if (!acc[name]) {
        acc[name] = { count: 0, totalPrice: 0 };
      }

      acc[name].count += 1;
      acc[name].totalPrice += price;

      return acc;
    },
    {},
  );

  const result = Object.entries(addonTotals).map(([addonName, { count, totalPrice }]) => {
    return {
      addonsString: `${count} x ${addonName}`,
      addonsPriceInPence: totalPrice,
    };
  });

  return result;
};

export const getFullTicketsPricePerClass = (classItem: BookedClass) => {
  let totalTicketsPrice = 0;
  classItem.bookedTickets.forEach((ticket) => {
    return (totalTicketsPrice += Number(ticket.originalAmount / 100));
  });

  let totalPerBookingAddOnPrice = 0;
  classItem.bookedTickets.forEach((ticket) => {
    ticket.attendees.forEach((ticketAttendee) => {
      ticketAttendee.addons.perBooking.forEach((bookingAddon) => {
        if (bookingAddon.price) {
          totalPerBookingAddOnPrice +=
            (Number(bookingAddon.price) * Number(bookingAddon?.quantity || 1)) / 100;
        }
      });
    });
  });

  let totalPerSessionAddOnPrice = 0;
  classItem.bookedTickets.forEach((ticket) => {
    ticket.attendees.forEach((ticketAttendee) => {
      ticketAttendee.addons.perSession.forEach((addon) => {
        if (addon.price) {
          totalPerSessionAddOnPrice += Number(addon.price) / 100;
        }
      });
    });
  });

  const totalPrice = totalTicketsPrice + totalPerBookingAddOnPrice + totalPerSessionAddOnPrice;

  return totalPrice;
};

export const getFullTicketsPricePerClassBooking = (classItem: BookedClass) => {
  let totalTicketsPrice = 0;
  classItem.bookedTickets.forEach((ticket) => {
    return (totalTicketsPrice += Number(ticket.originalAmount / 100));
  });

  let totalPerBookingAddOnPrice = 0;
  classItem.bookedTickets.forEach((ticket) => {
    if (!ticket.addonsByAttendee) return (totalPerBookingAddOnPrice += 0);
    ticket.addonsByAttendee.forEach((attendeeAddon) => {
      attendeeAddon.bookingAddons.forEach((bookingAddon) => {
        if (bookingAddon.price) {
          totalPerBookingAddOnPrice +=
            (Number(bookingAddon.price) * Number(bookingAddon?.quantity || 1)) / 100;
        }
      });
    });
  });

  let totalPerSessionAddOnPrice = 0;
  classItem.bookedTickets.forEach((ticket) => {
    if (!ticket.addonsByAttendee) return (totalPerBookingAddOnPrice += 0);
    ticket.addonsByAttendee.forEach((attendeeAddon) => {
      attendeeAddon.sessionAddons.forEach((addon) => {
        if (addon.price) {
          totalPerSessionAddOnPrice += Number(addon.price) / 100;
        }
      });
    });
  });

  const totalPrice = totalTicketsPrice + totalPerBookingAddOnPrice + totalPerSessionAddOnPrice;

  return totalPrice;
};
