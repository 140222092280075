import { PreCheckoutBasketTicket } from 'interfaces';
import React from 'react';
import classes from './BookedSubscriptionInfo.module.scss';
import { Group, Stack, Text, useMantineTheme } from '@mantine/core';
import { Ticket } from '@phosphor-icons/react';
import { formatPenceToPounds } from 'utils/formatPrice';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Activity } from 'types';
import { SubscriptionTrialTypeEnum } from 'enums';
import classNames from 'classnames';
dayjs.extend(customParseFormat);

export interface IBookedSubscriptionInfoProps {
  filteredTickets: PreCheckoutBasketTicket[];
  activity: Activity;
  trialEndDate?: string;
  trialEndMonth?: string;
  firstStandardMonth?: string;
}

const BookedSubscriptionInfo: React.FC<IBookedSubscriptionInfoProps> = ({
  filteredTickets,
  trialEndDate,
  activity,
  trialEndMonth,
  firstStandardMonth,
}) => {
  const theme = useMantineTheme();
  const currentMonth = dayjs().format('MMMM');
  const deferredStartDate = activity.subscriptionStart ? dayjs(activity.subscriptionStart) : null;
  const isDeferred = deferredStartDate ? deferredStartDate.isAfter(dayjs()) : false;

  return (
    <>
      {filteredTickets.map((ticket) => {
        const singleTicketPrice = ticket.ticketPrice;
        const hasFreeTrial =
          activity.subscriptionTrialType === SubscriptionTrialTypeEnum.FREE_TRIAL &&
          ticket.subscriptionTrialSelected;
        const hasPaidTrial =
          activity.subscriptionTrialType === SubscriptionTrialTypeEnum.PAID_TRIAL &&
          ticket.subscriptionTrialSelected;
        const hasTrial = hasFreeTrial || hasPaidTrial;
        const remainingSessionsCount = ticket.product?.prorata?.remainingSessions?.length || 0;
        const trialSessionsCount = activity.subscriptionTrialSessionCount || 0;
        const proratedCost = remainingSessionsCount * (ticket.ticketProrataPrice || 0);
        const cappedCost = Math.min(ticket.ticketPrice, proratedCost);
        const paidTrialCost = activity.subscriptionTrialPrice || 0;
        const isFree = !hasPaidTrial && (proratedCost === 0 || hasFreeTrial || isDeferred);

        return (
          <div key={ticket.id}>
            <Text size="xs" c={theme.colors.blue[8]}>
              The standard monthly cost for your subscription is:
            </Text>
            <div className={classes.sessionCostSection}>
              <Stack gap={4}>
                <Group gap="xs" align="flex-start" wrap="nowrap">
                  <Ticket
                    weight="fill"
                    size={18}
                    color={theme.colors.blue[8]}
                    className={classes.icon}
                  />

                  <Text className={classes.priceHeader} fw={700}>
                    {ticket.ticketName}
                  </Text>
                </Group>
              </Stack>
              {singleTicketPrice === 0 ? (
                <Text c={theme.colors.pink[6]} fw={700}>
                  Free
                </Text>
              ) : (
                <Text className={classes.price} fw={700}>
                  {formatPenceToPounds(ticket.ticketPrice)}
                </Text>
              )}
            </div>
            {hasTrial && (
              <Stack mt="md">
                <div>
                  <Text size="xs" c={theme.colors.blue[8]}>
                    You have{' '}
                    <Text fw={700} component="span">
                      {activity.subscriptionTrialSessionCount} {hasFreeTrial ? 'Free' : 'Paid'}{' '}
                      Trial
                    </Text>{' '}
                    sessions. Your trial ends on{' '}
                    <Text fw={700} component="span">
                      {trialEndDate}.
                    </Text>{' '}
                    On this date you will be charged a pro-rated amount based on the remaining
                    number of sessions in{' '}
                    <Text fw={700} component="span">
                      {trialEndMonth}.
                    </Text>{' '}
                    This is what you’ll pay so you can attend the activity in{' '}
                    <Text fw={700} component="span">
                      {trialEndMonth}
                    </Text>{' '}
                    before paying for the full subscription on the 1st of{' '}
                    <Text fw={700} component="span">
                      {firstStandardMonth}.
                    </Text>{' '}
                    You will then be charged on the 1st of each month moving forward.
                  </Text>{' '}
                </div>
              </Stack>
            )}
            {!hasTrial && isDeferred && (
              <div className={classes.sessionCostSection}>
                <Stack>
                  <Text size="xs" c={theme.colors.blue[8]}>
                    This subscription does not begin until{' '}
                    <Text fw={700} component="span">
                      {deferredStartDate?.format('DD/MM/YYYY')}
                    </Text>{' '}
                    - the first payment for this activity will be taken on that date.
                  </Text>
                  <Text size="xs" c={theme.colors.blue[8]}>
                    We'll calculate the amount you need to pay on{' '}
                    <Text fw={700} component="span">
                      {deferredStartDate?.format('DD/MM/YYYY')}
                    </Text>{' '}
                    based on the remaining number of sessions in{' '}
                    <Text fw={700} component="span">
                      {deferredStartDate?.format('MMMM')}
                    </Text>{' '}
                    . This is what you'll pay so you can join the activity in{' '}
                    <Text fw={700} component="span">
                      {deferredStartDate?.format('MMMM')}
                    </Text>{' '}
                    .
                  </Text>
                </Stack>
              </div>
            )}
            {!hasTrial && !isDeferred && (
              <div className={classes.sessionCostSection}>
                <Text size="xs" c={theme.colors.blue[8]}>
                  The cost to join this activity in{' '}
                  <Text fw={700} component="span">
                    {currentMonth}
                  </Text>{' '}
                  will be pro-rated as you are starting part way through the month. The{' '}
                  <Text fw={700} component="span">
                    {currentMonth}
                  </Text>{' '}
                  cost based on the remaining number of sessions will be:
                </Text>
              </div>
            )}
            <Group mt="md" justify="space-between" align="flex-start">
              <Stack gap={0}>
                <Text c={theme.colors.blue[8]} fw={700}>
                  Total to pay now
                </Text>
                {!hasTrial && !isDeferred && (
                  <Text size="sm" c={theme.colors.gray[6]} fw={700}>
                    ({remainingSessionsCount} x {currentMonth} sessions)
                  </Text>
                )}
                {hasFreeTrial && (
                  <Text size="sm" c={theme.colors.gray[6]} fw={700}>
                    ({trialSessionsCount} x Free trial sessions)
                  </Text>
                )}
                {hasPaidTrial && (
                  <Text size="sm" c={theme.colors.gray[6]} fw={700}>
                    ({trialSessionsCount} x Paid trial sessions)
                  </Text>
                )}
              </Stack>
              <Text
                className={classNames(classes.price, {
                  [classes.freeTotal]: isFree,
                })}
                fw={700}
                data-testid="total-cost"
              >
                {isFree && 'Free'}
                {hasPaidTrial && formatPenceToPounds(paidTrialCost)}
                {!isFree && !hasPaidTrial && formatPenceToPounds(cappedCost)}
              </Text>
            </Group>
            <div className={classes.sessionCostSection}>
              {!hasTrial && !isDeferred ? (
                <Text size="xs" c={theme.colors.blue[8]}>
                  You will then be charged the standard monthly cost on the 1st of each month going
                  forward.
                </Text>
              ) : (
                <Text size="xs" c={theme.colors.blue[8]}>
                  On the next screen, you'll enter your payment details, which will be saved and
                  used when the payment is processed.
                </Text>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default BookedSubscriptionInfo;
