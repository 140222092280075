import { useEffect, useState } from 'react';
import { Box, Tabs, useMantineTheme } from '@mantine/core';
import { ActivityDetails, ClassesType, Product, ProductsByDate } from 'types';
import { ActivityBlockSubtypeEnum, ActivityTypeEnum, DaysOfWeekEnum } from 'enums';
import { BlockBooking, SingleSession, Subscriptions } from '@icons';
import classes from 'components/ActivityDetails/ActivityDetails.module.scss';
import { trackAction, Actions } from 'utils/amplitude';
import RadioTimePicker from './RadioTimePicker/RadioTimePicker';
import SessionsByDatePicker from './SessionsByDatePicker/SessionsByDatePicker';
import { PebbleSelect } from 'components/ui';
import BookContainer from './BookContainer/BookContainer';
import SessionPicker from './SessionsByDatePicker/SessionPicker/SessionPicker';
import classNames from 'classnames';
import queryString from 'query-string';
import { CaretDown } from '@phosphor-icons/react';

interface ITimesAndAvailabilityProps {
  activityDetails: ActivityDetails;
  blockBookingTabActive: boolean;
  setBlockBookingTabActive: (value: boolean) => void;
  selectedSession: Product | null;
  setSelectedSession(val: Product | null): void;
  setUserSelectedRadio?(val: boolean): void;
  selectedClass: ClassesType;
}

const getDefaultSelectedSessionSingle = (
  selectedDate: string | undefined,
  sessions: ProductsByDate[],
) => {
  if (selectedDate === '' || selectedDate === sessions[0]?.products[0].singleSessionDate) {
    return sessions[0]?.products[0];
  } else {
    const findSession = sessions.filter((x) => x.date === selectedDate);
    return findSession[0]?.products[0];
  }
};

const getDefaultSelectedSessionBlockOrSub = (
  dayOfWeek: DaysOfWeekEnum | undefined,
  sessions: Product[],
) => {
  if (dayOfWeek) {
    const findBlock = sessions.filter((x) => {
      return x.weekdays[0] === dayOfWeek;
    });
    return findBlock[0];
  } else {
    return sessions[0];
  }
};

const getDefaultSession = (
  blocks: Product[],
  singleDate: ProductsByDate,
  isBlockBooking: boolean,
  singleSessions: ProductsByDate[],
  blockBookingTabActive: boolean,
  selectedDateOnDatePicker: string | undefined,
  selectedDayOfWeek: DaysOfWeekEnum | undefined,
): Product | null => {
  if (isBlockBooking && blockBookingTabActive) {
    return getDefaultSelectedSessionBlockOrSub(selectedDayOfWeek, blocks);
  }

  if (singleDate) {
    return getDefaultSelectedSessionSingle(selectedDateOnDatePicker, singleSessions);
  }

  return null;
};

const TimesAndAvailability: React.FC<ITimesAndAvailabilityProps> = ({
  activityDetails,
  blockBookingTabActive,
  setBlockBookingTabActive,
  selectedSession,
  setSelectedSession,
  setUserSelectedRadio,
  selectedClass,
}) => {
  const theme = useMantineTheme();

  const { activity } = activityDetails;
  const { blocks, sessions } = selectedClass;

  // duplicate can pass down later
  const hasBlockTickets = selectedClass.tickets.block.some((ticket) => ticket.isAvailable);
  const hasIndividualTickets = selectedClass.tickets.individual.some(
    (ticket) => ticket.isAvailable,
  );

  const [activeBookingTab, setActiveBookingTab] = useState<string | null>(
    blockBookingTabActive ? 'block' : 'single',
  );

  //handle initial active tab state when selecting a class
  useEffect(() => {
    if (activity.allowBlockBookings && hasBlockTickets && selectedClass) {
      setActiveBookingTab('block');
      setBlockBookingTabActive(true);
    } else {
      setActiveBookingTab('single');
      setBlockBookingTabActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClass]);

  const isBlockBooking = activity.allowBlockBookings;
  const isSingleDate = sessions.length === 1;
  const [singleDate] = sessions;
  const isMultiDate = sessions?.length > 1;
  const isSingleTime = singleDate?.products?.length === 1;
  const isMultiTime = singleDate?.products?.length > 1;

  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState<DaysOfWeekEnum>(
    selectedClass.weekdays[0],
  );

  const filteredBlockBookings = blocks.filter((session) => {
    if (selectedDayOfWeek && session.weekdays) return session.weekdays.includes(selectedDayOfWeek);
  });

  const [selectedDateOnDatePicker, setSelectedDatePicker] = useState<string | undefined>('');

  useEffect(() => {
    setSelectedSession(
      getDefaultSession(
        blocks,
        singleDate,
        isBlockBooking,
        sessions,
        activeBookingTab === 'block',
        selectedDateOnDatePicker,
        selectedDayOfWeek,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClass, activeBookingTab, selectedDayOfWeek, selectedDateOnDatePicker]);

  useEffect(() => {
    setSelectedDayOfWeek(selectedClass?.weekdays[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClass]);

  const params = {
    checkoutKey: selectedSession?.checkoutKey,
    checkoutValue: selectedSession?.checkoutValue,
  };
  const paramsString = queryString.stringify(params);

  const waitlistLink = `/waitlist/${activity.id}?${paramsString}`;

  const twoTabs =
    activity.allowIndividualBookings &&
    activity.allowBlockBookings &&
    hasBlockTickets &&
    hasIndividualTickets;

  return (
    <>
      <Box className={classes.sectionText}>
        <Tabs
          classNames={{
            list: classes.bookingsTabsList,
            tab: twoTabs ? classes.bookingsTab : classes.bookingsTabOneTabOnly,
            panel: classNames(!blockBookingTabActive ? classes.calendarTabPanel : classes.tabPanel),
          }}
          keepMounted={false}
          value={activeBookingTab}
          onChange={setActiveBookingTab}
        >
          <Tabs.List>
            {blocks.length > 0 && hasBlockTickets && (
              <Tabs.Tab
                onClick={() => {
                  setBlockBookingTabActive(true);
                  setActiveBookingTab('block');
                }}
                className={!activity.allowIndividualBookings ? classes.disablePointer : ''}
                leftSection={<BlockBooking color={theme.colors.blue[8]} size={twoTabs ? 16 : 20} />}
                value="block"
              >
                Block
              </Tabs.Tab>
            )}
            {activity.allowIndividualBookings && hasIndividualTickets && (
              <Tabs.Tab
                onClick={() => {
                  setBlockBookingTabActive(false);
                  setActiveBookingTab('single');
                }}
                className={!activity.allowBlockBookings ? classes.disablePointer : ''}
                value="single"
                leftSection={
                  <SingleSession color={theme.colors.blue[8]} size={twoTabs ? 16 : 20} />
                }
              >
                Single
              </Tabs.Tab>
            )}

            {activity.activityType === ActivityTypeEnum.SUBSCRIPTION && (
              <Tabs.Tab
                className={!activity.allowBlockBookings ? classes.disablePointer : ''}
                value="subscription"
                leftSection={<Subscriptions color={theme.colors.blue[8]} />}
              >
                Monthly subscription
              </Tabs.Tab>
            )}
          </Tabs.List>

          <Tabs.Panel value="single">
            {isSingleDate && isSingleTime && (
              <SessionPicker
                products={singleDate.products}
                hasAvailableTickets={hasIndividualTickets}
                hideEndTimes={activity.hideEndTimes}
                isSingleDate={isSingleDate}
              />
            )}

            {isSingleDate && isMultiTime && (
              <SessionPicker
                products={singleDate.products}
                hasAvailableTickets={hasIndividualTickets}
                hideEndTimes={activity.hideEndTimes}
                isSingleDate={isSingleDate}
              />
            )}

            {isMultiDate && (
              <SessionsByDatePicker
                sessionsByDate={sessions}
                selectedSession={selectedSession}
                onSelect={setSelectedSession}
                onClearSession={() => setSelectedSession(null)}
                blockBookingTabActive={blockBookingTabActive}
                setSelectedDatePicker={setSelectedDatePicker}
                hasAvailableTickets={hasIndividualTickets}
                hideEndTimes={activity.hideEndTimes}
                selectedClass={selectedClass}
                isSingleDate={isSingleDate}
              />
            )}
          </Tabs.Panel>
          <Tabs.Panel value="block">
            {activity.blockSubtype === ActivityBlockSubtypeEnum.RECURRING &&
              selectedClass.weekdays.length > 1 && (
                <PebbleSelect
                  value={selectedDayOfWeek}
                  radius="md"
                  required
                  placeholder="Pick a day"
                  data={selectedClass.weekdays}
                  classNames={{
                    root: classes.dropdownRoot,
                    dropdown: classes.dropdown,
                    input: classes.input,
                    section: classes.rightSection,
                  }}
                  rightSection={<CaretDown size={18} weight="bold" color={theme.colors.blue[8]} />}
                  styles={{ section: { pointerEvents: 'none' } }}
                  onChange={(e) => {
                    setSelectedDayOfWeek(e as DaysOfWeekEnum);
                    trackAction(Actions.SELECTED_DAY);
                  }}
                />
              )}
            {/* For block all days */}
            {activity.blockSubtype === ActivityBlockSubtypeEnum.ALL_DAYS &&
              isBlockBooking &&
              blocks.length > 0 && (
                <>
                  <RadioTimePicker
                    products={blocks}
                    selectedSession={selectedSession}
                    onSelect={setSelectedSession}
                    setUserSelectedRadio={setUserSelectedRadio}
                    showViewAllDatesButtons
                    showDatesByWeek
                    hideEndTimes={activity.hideEndTimes}
                  />
                </>
              )}
            {/* For block recurring multi-day */}
            {activity.blockSubtype === ActivityBlockSubtypeEnum.RECURRING &&
              selectedClass.weekdays.length > 1 &&
              isBlockBooking &&
              blocks.length > 0 && (
                <>
                  <RadioTimePicker
                    products={filteredBlockBookings.length > 0 ? filteredBlockBookings : blocks}
                    setUserSelectedRadio={setUserSelectedRadio}
                    selectedSession={selectedSession}
                    onSelect={setSelectedSession}
                    showViewAllDatesButtons
                    hideEndTimes={activity.hideEndTimes}
                  />
                </>
              )}
            {/* For block recurring single day */}
            {activity.blockSubtype === ActivityBlockSubtypeEnum.RECURRING &&
              selectedClass.weekdays.length === 1 &&
              isBlockBooking &&
              blocks.length > 0 && (
                <>
                  <RadioTimePicker
                    products={filteredBlockBookings.length > 0 ? filteredBlockBookings : blocks}
                    setUserSelectedRadio={setUserSelectedRadio}
                    selectedSession={selectedSession}
                    onSelect={setSelectedSession}
                    showViewAllDatesButtons
                    hideEndTimes={activity.hideEndTimes}
                  />
                </>
              )}

            {(selectedSession?.spotsLeft === 0 || !hasBlockTickets) && (
              <BookContainer
                selectedSessionSpotsLeft={selectedSession?.spotsLeft}
                waitlistLink={waitlistLink}
                activityType={activity.activityType}
              />
            )}
          </Tabs.Panel>
        </Tabs>
      </Box>
    </>
  );
};

export default TimesAndAvailability;
